// import dashboards from "./dashboards"
// import policedashboard from "./policedashboard"
import policedashboard from "./policedashboard"
import applications from "./applications"
import pages from "./pages"
import userInterface from "./user-interface"

export default [
  // dashboards,
  policedashboard,
  applications,
  pages,
  userInterface,
]